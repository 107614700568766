.green {
    color: #47B120;
}

.red {
    color: #DE1B1B
}

.answer-icon {
    margin-left: 0.5rem;
}

.question-answer {
    margin-left: 5rem;
}